import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";

import Footer from "@/components/footer";
import AboutFour from "@/components/about-four";
import VideoOne from "@/components/video-one";
import TestimonialsOne from "@/components/testimonials-one";

import SponsorOne from "@/components/sponsor-one";
import AboutTwo from "@/components/about-two";
import HeaderWrapper from "@/components/header-wrapper";
import AboutUsBanner from "@/images/DDImages/aboutUsBanner.png";

const About = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Page">
        <HeaderOne />
        {/* <PageHeader title="About Us" name="About" /> */}
        <HeaderWrapper title="About Us" name="About" banner={AboutUsBanner} />
        <AboutFour />
        <VideoOne />
        <AboutTwo />
        <TestimonialsOne />
        {/* <TeamOne extraClassName="section_border" /> */}
        <SponsorOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;
